<template>
  <b-card>
    <!-- Alert: No item found -->
    <b-tabs>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <div>

          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Personal Information
            </h4>
          </div>
          <b-row>
              <b-col md="8" lg="9">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>

              <!-- Field: Mobile -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Display Name"
                  label-for="display_name"
                >
                  <b-form-input
                    id="display_name"
                    v-model="userData.display_name"
                  />
                </b-form-group>
              </b-col>
              
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <b-form-input
                    id="username"
                    v-model="userData.username"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    v-model="userData.status"
                    :reduce="option => option.value"
                    :options="statusOptions"
                    :clearable="false"
                    input-id="status"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Mobile"
                  label-for="Mobile"
                >
                  <b-form-input
                    id="mobile"
                    v-model="userData.mobile"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Role"
                  label-for="role"
                >
                  <v-select
                    v-model="userData.role"
                    :reduce="ans => ans.value"
                    :options="roleOptions"
                    :clearable="false"
                    input-id="role"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Access Centers"
                  label-for="access_centers"
                >
                  <v-select
                    v-model="userData.access_center"

                    :options="centerOptions"
                    :clearable="false"
                    multiple
                    input-id="access_center"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                lg="4"
              > 
                <b-form-group
                  label="Default Login Centers"
                  label-for="default_center_id"
                >
                  <v-select
                    v-model="userData.default_center_id"
                    label="label"
                    :reduce="c => c.value"
                    :options="centerOptions"
                    :clearable="false"
                    input-id="default_center_id"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="save_staff"
                >
                  Save Changes
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :to="{ name: 'staff-list' }"
                >
                  Cancel
                </b-button>
                <b-button
                  variant="danger"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="reset_password"
                >
                  Reset Password
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col md="4" lg="3">
            <!-- <img
              style="width:100%;border-radius:10px;"
              ref="previewEl"
              @error="userData.avatar = ''"
              :src="userData.avatar"
              size="100%"
              rounded
            /><br> -->
            <!-- <b-button
              class="mt-1"
              block
              variant="primary"
              @click="go()"
            >
            <feather-icon icon="CameraIcon" />
             <span> {{ $t('Start Camera') }} </span>
            </b-button> -->
            <b-button
              class="mt-1"
              block
              variant="outline-primary"
              @click="update_card()"
            >
            <feather-icon icon="RssIcon" />
            <span  v-if="userData.card_id"> {{ $t('Update Card') }} </span>
            <span  v-if="!userData.card_id"> {{ $t('Register Card') }} </span>
            </b-button> 
            <hr>
        <h4 class="mb-2"> <feather-icon icon="DoorIcon" size="19" /> Access Zones</h4>
            <b-list-group>
              <v-select
                    v-model="selected_zone"
                    :reduce="option => option.value"
                    :options="zoneOptions"
                    :clearable="false"
                    input-id="status"

                  />
                  <b-button class="mb-1" size="sm"  variant="success" @click="control_zone('add',selected_zone)">
                  <span> Submit </span>
                  </b-button> 
                <b-list-group-item v-for="zone in userData.access_zone" :key="index" class="d-flex justify-content-between align-items-center">
                    <span><b>{{zone.label}}</b><br><span style="font-size:13px;color:#aaa;"></span></span>
                    
                    <b-badge variant="danger" pill class="badge-round" @click="control_zone('delete',zone.value)">
                        <feather-icon icon="Trash2Icon" size="19" style="color:red"/>
                    </b-badge>
                </b-list-group-item>

            </b-list-group>
        </b-col>
      </b-row>
        </div>
      </b-tab>
      <b-tab  v-if="client_module.module.includes('staff_package')">
        <template #title>
          <feather-icon
            icon="ActivityIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Package</span>
        </template>
        <tab-staff-package
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="LogInIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Entry</span>
        </template>
        <tab-staff-entry
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="TargetIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Commission</span>
        </template>
        <tab-staff-commission
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem,BAlert, BLink, BCard, BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import tabStaffPackage from './TabStaffPackage.vue'
import tabStaffEntry from './TabStaffEntry.vue'
import tabStaffCommission from './TabStaffCommission.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  components: {
    ToastificationContent,
    tabStaffPackage,
    BListGroup, BListGroupItem,
    tabStaffCommission,
    tabStaffEntry,
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
  data() {
    return {
      client_module:JSON.parse(localStorage.getItem('userData')),
      centerOptions: [],
      selected_zone:null,
      zoneOptions: [],
      userData: {
        display_name: null,
        exsiting_username: null,
      },
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Manager', value: 'manager' },
        { label: 'Staff', value: 'staff' },
        { label: 'Senior PT', value: 'senior_pt' },
        { label: 'PT', value: 'pt' },
        { label: 'Rental PT', value: 'rental_pt' },
      ],
    }
  },
  created() {
    console.log('in')
    this.get_staff_data()
    this.get_center_data()
    this.get_zone_data()

  },
  methods: {
    control_zone(action, zone_id) {
      this.$http.post(process.env.VUE_APP_API_BASE+`/staff/${this.userData.user_id}/zone/`+action+`/`, {zone_id : zone_id})
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
            this.selected_zone = null
            this.get_staff_data()

          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    update_card() {
      
         this.$swal({
          title: 'Please Scan IC Card',
          icon: 'info',
          input: 'text',
          allowOutsideClick: false,
          showCancelButton: true,

          //confirmButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn outline-secondary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$http.post(process.env.VUE_APP_API_BASE+`/staff/${this.userData.user_id}/card/`, {card_id : result.value} )
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
          }
        })
    },
    go() {
        window.location = 'https://www.cloudfit.pro/snapshot.php?type=user&token='+this.userData.token
    },
    save_staff() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/staff/${this.userData.user_id}/`, this.userData)
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    get_zone_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/zones/')
        .then(res => {
          this.zoneOptions = res.data.zones
        })
    },
    reset_password() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/staff/reset_password/${router.currentRoute.params.id}/`)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'information',
            },
          })
        })
    },
    get_staff_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staff/${router.currentRoute.params.id}/`)
        .then(res => {
          this.userData = res.data
        })
    },
  },
}
</script>

<style>

</style>
